import React from 'react';
import Layout from '../Layout';
import "./style.scss";
import sanjithaPic from "../../images/sanjitha-mohapatra.jpg";
import SEO from '../seo';

import shareicon from "../../images/volunteer-icon-3.png"
import { RWebShare } from 'react-web-share';


const BlazingTrailPage = ({ location }) => {
    return (
        <Layout>
            <SEO
                title="Chinmaya Vidyalaya Success Stories"
                description="Meet Sanjitha Mahopatra, a student of Chinmaya Vidyalaya Chhend Colony Rourkela. "
                keywords=""
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='blog'>
                <h1>Blazing a Trail of Success</h1>

                <div className="divider"></div>

                <img className='blog__img' src={sanjithaPic}></img>
                <p>

                    Sanjitha Mahopatra, a student of CV Chhend Colony Rourkela has been ranked 10 in the UPSC exam at the National level in 2020.<br /><br />
                    While Sanjitha was in grade VI, her parents were unable to pay the fee and wanted to withdraw Sanjitha and her sister from school.<br /><br />
                    The Chinmaya Education Cell (CEC) offered them the scholarship instead, to help them complete their education.
                    She recently addressed the students of Chinmaya Vidyalaya and who were interested in taking up the Civil Services as their career. She spoke with eloquence and inspired the students with her life story.<br /><br />
                    Currently she is serving as a Deputy Collector posted in Maharashtra and is proud that her signature will make a difference to the lives of people!
                    <br /><br />
                    <RWebShare data={{
                        text: "",
                        url: `https://${location.host}/get-inspired/blazing-a-trail-of-success`,
                        title: "Share this article"
                    }}>
                        <button style={{ background: "transparent", border: "none", color: "#EE896D" }} className='share-btn'>
                            <img width={22} height={22} src={shareicon} style={{ transform: "scaleX(-1)" }}></img>   SHARE
                        </button>
                    </RWebShare>
                </p>
            </div>
        </Layout>
    );
}

export default BlazingTrailPage;
